<template>
  <div class="mobile-detail">
    <div class="content_container full-line" style="margin-bottom: 0">
      <cube-scroll v-if="detail">
        <inputItem title="催款类型" :initialContent="getTypeName(detail.type)" :disabled="true"></inputItem>
        <inputItem title="业务代表" :initialContent="detail.user_name" :disabled="true"></inputItem>
        <inputItem title="项目名称" :initialContent="detail.pname" :disabled="true"></inputItem>
        <inputItem title="合计金额" :initialContent="detail.total" :disabled="true"></inputItem>
        <div v-html="detail.user_name+'，您好！'" style="line-height: 24px;font-size: 14px;text-align: left;padding: 10px 10px 0;"></div>
        <div v-html="detail.content" style="line-height: 24px;font-size: 14px;text-align: left;padding: 0 10px;"></div>
        <div v-html="project.owner" style="line-height: 24px;font-size: 14px;text-align: right;padding: 0 10px;"></div>
        <div v-html="formatDate(detail.time)" style="line-height: 24px;font-size: 14px;text-align: right;padding: 0 10px;"></div>
      </cube-scroll>
    </div>
  </div>
</template>

<script>
import { GetRemindList } from "@/api";
import { formatDate } from "@/utils/format.js";
import { loadDetailAction } from "@/utils/action.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      project: null,
    };
  },
  methods: {
    formatDate: formatDate,
    getTypeName: function (type) {
      switch (type) {
        case 1:
          return "即将到期";
        default:
          return "已经逾期";
      }
    },
    dataRequest: function () {
      loadDetailAction(this, GetRemindList, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.project = data.list[0].project;
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>